<div class="o8-action-snack-message">
  <div class="action-snack-message accent-color">
    <span>{{ data.msg }}</span>
  </div>
  <div class="action-snack-buttons-container">
    @if (showActionBtn) {
      <button
        mat-button
        matsnackbaraction
        class="snack-action-button"
        (click)="snackBarRef.dismissWithAction()"
        >
        {{ data.actionText }}
      </button>
    }
    @if (showDismissBtn) {
      <button
        mat-button
        matsnackbaraction
        class="snack-dismiss-button"
        (click)="snackBarRef.dismiss()"
        >
        Dismiss
      </button>
    }
  </div>

</div>

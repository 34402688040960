import { ComponentType } from '@angular/cdk/portal';
import { forwardRef, Inject, Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { ErrorSnackBarComponent } from './error/error.snack-message.component';
import { InfoSnackBarComponent } from './info/info.snack-message.component';
import { ActionSnackMessageComponent } from './action/action.snack-message.component';

@Injectable({ providedIn: 'root' })
export class SnackMessageService {
  constructor(
    @Inject(forwardRef(() => MatSnackBar))
    private _snackBar: MatSnackBar,
    @Inject(forwardRef(() => NgZone))
    private zone: NgZone,
  ) {}

  private show(msg: string, component: ComponentType<any>, opts?: MatSnackBarConfig<any>) {
    const config = { verticalPosition: 'bottom', horizontalPosition: 'center', ...opts } as MatSnackBarConfig<any>;
    // Needs zone because of https://github.com/angular/components/issues/13181
    this.zone.run(() => {
      this._snackBar.dismiss();
      return this._snackBar.openFromComponent(component, {
        data: {
          msg,
        },

        duration: 10000,
        ...config,
      });
    });
  }

  private openWithAction(
    component: ComponentType<any>,
    msg: string,
    actionText: string,
    showDismissBtn: boolean,
    opts?: MatSnackBarConfig<any>,
  ): MatSnackBarRef<any> {
    const config = { verticalPosition: 'bottom', horizontalPosition: 'center', ...opts } as MatSnackBarConfig;
    return this.zone.run(() => {
      this._snackBar.dismiss();
      return this._snackBar.openFromComponent(component, {
        data: {
          msg,
          actionText,
          showDismissBtn,
        },
        ...config,
      });
    });
  }

  showInfo(msg: string, opts?: MatSnackBarConfig) {
    this.show(msg, InfoSnackBarComponent, { panelClass: 'snack-info', ...opts });
  }

  showError(msg?: string, opts?: MatSnackBarConfig) {
    const message = msg ?? 'An error occured please try again later';
    this.show(message, ErrorSnackBarComponent, { panelClass: 'snack-error', ...opts });
  }

  showWithAction(
    message: string,
    actionText = '',
    showDismissBtn = true,
    opts?: MatSnackBarConfig,
  ): MatSnackBarRef<any> {
    return this.openWithAction(ActionSnackMessageComponent, message, actionText, showDismissBtn, {
      panelClass: 'snack-action',
      ...opts,
    });
  }
}
